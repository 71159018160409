import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import * as FullStory from '@fullstory/browser';
import { getSessionStorage } from '@whitelabel/helpers/storageUtils';
import {
  allowedPartner,
  ZENDESK_CHAT_APP_ID,
  isAmazonGroup,
  isTicketingPartner,
  allowedVertical,
} from '../../helpers/chatWidget';
import { ZENDESK_CHAT_PARTNER } from '../../helpers/constants';
import { ZendeskChatContext } from './context';

const ZendeskChatProvider = ({ children }: { children: ReactNode }) => {
  const [isZendeskChatOpen, setIsZendeskChatOpen] = useState<boolean>(false);
  const zendeskChatPartnerFromSession = getSessionStorage(ZENDESK_CHAT_PARTNER);

  const getZendeskChatAPPID = (partnerSlug: string) => {
    if (isAmazonGroup(partnerSlug)) {
      return ZENDESK_CHAT_APP_ID[allowedPartner.amazonGroup];
    }

    if (isTicketingPartner(partnerSlug)) {
      return ZENDESK_CHAT_APP_ID[allowedVertical.ticketing];
    }

    return ZENDESK_CHAT_APP_ID[partnerSlug];
  };

  const launchZendeskChat = useCallback(
    (partnerSlug: string) =>
      new Promise<void>((resolve) => {
        const zendeskChatAPPID = getZendeskChatAPPID(partnerSlug);

        if (!zendeskChatAPPID) {
          resolve();
          return;
        }

        // clean up previous script
        const previousScript = document.getElementById('ze-snippet');
        previousScript?.remove();

        const scriptURL = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskChatAPPID}`;
        const script = document.createElement('script');
        script.src = scriptURL;
        script.async = true;
        script.id = 'ze-snippet';
        script.onload = () => {
          setIsZendeskChatOpen(true);
          resolve();
        };
        document.body.appendChild(script);
      }),
    [],
  );

  const openChat = useCallback(async (partnerSlug: string) => {
    if (!window.isHeadless && window.bwtag) {
      window.bwtag('record', 'click', {
        analyticsID: 'launch-chat-button',
        partnerSlug,
        openChat: true,
      });
      FullStory.event('ZendeskChat', {
        partnerSlug,
        openChat: true,
      });
    }

    window.zE?.('messenger', 'logoutUser');
    sessionStorage.setItem(ZENDESK_CHAT_PARTNER, partnerSlug);
    // reload the page to run the new script
    window.location.reload();
  }, []);

  useEffect(() => {
    // load Zendesk Chat if partner slug is in session storage
    if (zendeskChatPartnerFromSession) {
      const securityToken = new URLSearchParams(window.location.search).get('security_token');
      // clean up chat history for switch to login-less FNOL flow
      if (securityToken) {
        window.zE?.('messenger', 'logoutUser');
        window.zE?.('messenger', 'hide');
        return;
      }
      void launchZendeskChat(zendeskChatPartnerFromSession);
    } else {
      window.zE?.('messenger', 'logoutUser');
      window.zE?.('messenger', 'hide');
      setIsZendeskChatOpen(false);
    }
  }, [launchZendeskChat, zendeskChatPartnerFromSession]);

  useEffect(() => {
    if (isZendeskChatOpen) {
      window.zE?.('messenger', 'show');
      window.zE?.('messenger', 'open');
    }
  }, [isZendeskChatOpen]);

  const contextValue = useMemo(
    () => ({
      openChat,
      isZendeskChatOpen,
    }),
    [isZendeskChatOpen, openChat],
  );

  return <ZendeskChatContext.Provider value={contextValue}>{children}</ZendeskChatContext.Provider>;
};

export default ZendeskChatProvider;
