import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  required: {
    id: 'helpers.validation.required',
    defaultMessage: 'This is required',
  },
  match: {
    id: 'helpers.validation.match',
    defaultMessage: `Fields don't match`,
  },
  minLength: {
    id: 'helpers.validation.minLength',
    defaultMessage: 'Please enter at least {min} characters',
  },
  maxLength: {
    id: 'helpers.validation.maxLength',
    defaultMessage: 'Please enter at most {max} characters',
  },
  minItems: {
    id: 'helpers.validation.minItems',
    defaultMessage: 'Please select at least {min} items',
  },
  maxItems: {
    id: 'helpers.validation.maxItems',
    defaultMessage: 'Please select at most {max} items',
  },
  isAlpha: {
    id: 'helpers.validation.isAlpha',
    defaultMessage: 'Must be letters only',
  },
  noSpaceAtStartAndEnd: {
    id: 'helpers.validation.noSpaceAtStartAndEnd',
    defaultMessage: `Please choose a password that doesn't have a space at the start or end.`,
  },
  containsAlpha: {
    id: 'helpers.validation.containsAlpha',
    defaultMessage: 'Must contain letters',
  },
  containsLowerAlpha: {
    id: 'helpers.validation.containsLowerAlpha',
    defaultMessage: 'Must contain a lowercase letter',
  },
  containsUpperAlpha: {
    id: 'helpers.validation.containsUpperAlpha',
    defaultMessage: 'Must contain an uppercase letter',
  },
  minWords: {
    id: 'helpers.validation.minWords',
    defaultMessage: 'Must be {min} words or more',
  },
  isAlphaNumeric: {
    id: 'helpers.validation.isAlphaNumeric',
    defaultMessage: 'Must be letters and numbers only',
  },
  isNumber: {
    id: 'helpers.validation.isNumber',
    defaultMessage: 'Please only use numbers in this field',
  },
  isSupportedLocation: {
    id: 'helpers.validation.isSupportedCountry',
    defaultMessage:
      'Unfortunately we’re not covering your location at the moment. ' +
      'We’re looking to expand in the near future, so please try again at a later date.',
  },
  containsNumber: {
    id: 'helpers.validation.containsNumber',
    defaultMessage: 'Must contain a number',
  },
  minNumber: {
    id: 'helpers.validation.minNumber',
    defaultMessage: 'Number must be at least {min}',
  },
  maxNumber: {
    id: 'helpers.validation.maxNumber',
    defaultMessage: 'Number must be less than or equal to {max}',
  },
  integer: {
    id: 'helpers.validation.integer',
    defaultMessage: 'Please exclude any decimal points',
  },
  containsSpecialChars: {
    id: 'helpers.validation.containsSpecialChars',
    defaultMessage: 'Must contain one of the following characters: {chars}',
  },
  isEmail: {
    id: 'helpers.validation.isEmail',
    defaultMessage: 'Please enter a valid email address',
  },
  isPhone: {
    id: 'helpers.validation.isPhone',
    defaultMessage: 'Please enter a valid phone number',
  },
  isDateGeneric: {
    id: 'helpers.validation.isDateGeneric',
    defaultMessage: 'The date provided must follow the following format - DD-MMM-YYYY',
  },
  passwordRequirementsMinLength: {
    id: 'helpers.validation.passwordRequirementsMinLength',
    defaultMessage: 'A minimum of 8 characters',
  },
  passwordRequirementsContainsNumber: {
    id: 'helpers.validation.passwordRequirementsContainsNumber',
    defaultMessage: 'A number',
  },
  passwordRequirementsContainsUppercase: {
    id: 'helpers.validation.passwordRequirementsContainsUppercase',
    defaultMessage: 'An uppercase letter',
  },
  regionRequired: {
    id: 'helpers.validation.regionRequired',
    defaultMessage: 'Region is required',
  },
  regionSupported: {
    id: 'helpers.validation.regionSupported',
    defaultMessage: 'This region is not supported',
  },
  postcodeRequired: {
    id: 'helpers.validation.postcodeRequired',
    defaultMessage: 'Postcode is required',
  },
  address1Required: {
    id: 'helpers.validation.address1Required',
    defaultMessage: 'Address line 1 is required',
  },
  cityRequired: {
    id: 'helpers.validation.cityRequired',
    defaultMessage: 'City is required',
  },
  countryRequired: {
    id: 'helpers.validation.countryRequired',
    defaultMessage: 'Please select a country',
  },
  countrySupported: {
    id: 'helpers.validation.countrySupported',
    defaultMessage: 'This country is not supported',
  },
  locationError: {
    id: 'helpers.validation.locationError',
    defaultMessage:
      'Unfortunately we’re not covering your location at the moment. ' +
      'We’re looking to expand in the near future, so check back later.',
  },
  invalidType: {
    id: 'helpers.validation.invalidType',
    defaultMessage: 'The type is invalid',
  },
  invalidValue: {
    id: 'helpers.validation.invalidValue',
    defaultMessage: 'This is invalid',
  },
  hexValue: {
    id: 'helpers.validation.hexValue',
    defaultMessage: 'Please enter a valid HEX code including #',
  },
  invalidFreeAddress: {
    id: 'helpers.validation.invalidFreeAddress',
    defaultMessage: 'Please select an address from the suggestion list',
  },
  emailOrPhoneLabel: {
    id: 'form.emailOrPhoneLabel',
    defaultMessage: 'Email address or phone number (include country code)',
  },
  emailAddressLabel: {
    id: 'form.emailAddressLabel',
    defaultMessage: 'Email address',
  },
  phoneNumberLabel: {
    id: 'form.phoneNumberLabel',
    defaultMessage: 'Phone number',
  },
  emailOrPhoneHintText: {
    id: 'form.emailOrPhoneHintText',
    defaultMessage: 'For phone numbers, please include country code. e.g. +61123456789',
  },
  invalidEmailOrPhone: {
    id: 'form.invalidEmailOrPhone',
    defaultMessage: 'Please enter a valid email address or phone number',
  },
  dobMaxAge: {
    id: 'form.validation.dobMaxAge',
    defaultMessage: 'Please enter a valid date of birth',
  },
  refNumberInvalidMessage: {
    id: 'contactForm.refNumberInvalidMessage',
    defaultMessage:
      'Reference Number format is invalid. Please match this pattern XXXXX-XXXXX-INS or X-XXXXX-XXXXX-INS',
  },
  enterPhoneNumber: {
    id: 'form.enterPhoneNumber',
    defaultMessage:
      'Enter the phone number associated with your XCover Account and make sure the country code is correct.',
  },
  enterEmail: {
    id: 'form.enterEmail',
    defaultMessage:
      'Enter the email address associated with your XCover Account or, if it’s your first time logging in, view {link}.',
  },
  invalidDateTimeRange: {
    id: 'form.validation.invalidDateTimeRange',
    defaultMessage: 'Loss Date must be in the past',
  },
  invalidDate: {
    id: 'form.validation.invalidDate',
    defaultMessage: 'This date is not valid',
  },
  maxFiles: {
    id: 'form.validation.maxFiles',
    defaultMessage:
      'Uploads are limited to 5 documents. Please submit an additional form if you need to add extra documents.',
  },
  addAppropriateAddress: {
    id: 'helpers.validation.addAppropriateAddress',
    defaultMessage: 'A complete address including the street number, street, city, and postcode is required',
  },
  preciseLength: {
    id: 'form.validation.preciseLength',
    defaultMessage: 'Please enter {length} {length, plural, one {character} other {characters}}',
  },
  validEmail: {
    id: 'form.validation.validEmail',
    defaultMessage: '{fieldLabel} must be a valid email',
  },
});

export default messages;

export const yupMessages = {
  mixed: {
    required: JSON.stringify({ descriptor: messages.required }),
  },
  string: {
    email: JSON.stringify({ descriptor: messages.isEmail }),
    min: JSON.stringify({ descriptor: messages.minLength, value: { min: '${min}' } }), // eslint-disable-line
    max: JSON.stringify({ descriptor: messages.maxLength, value: { max: '${max}' } }), // eslint-disable-line
  },
  number: {
    isNumber: JSON.stringify({ description: messages.isNumber }),
    min: JSON.stringify({ descriptor: messages.minNumber, value: { min: '${min}' } }), // eslint-disable-line
    max: JSON.stringify({ descriptor: messages.maxNumber, value: { max: '${max}' } }), // eslint-disable-line
    integer: JSON.stringify({ descriptor: messages.integer }),
  },
};
