import { getUserCountry } from '@whitelabel/helpers/site';
import { TICKETING_PARTNERS, AMAZON_COUNTRY_CODES } from './constants';

export const allowedPartner = {
  revolut: 'revolut',
  amazonGroup: 'amazon',
};

export const allowedVertical = {
  ticketing: 'ticketing',
};

export const ZENDESK_CHAT_APP_ID: Partial<Record<string, string>> = {
  [allowedPartner.revolut]: '517074fc-dd89-474e-9f5f-adf6b35d570c',
  [allowedPartner.amazonGroup]: 'c77739ee-fa75-4122-b54f-ba77fbc7f282',
  [allowedVertical.ticketing]: 'f0f5ce3b-9830-48a3-a7fe-5602ceafd905',
};

export const isAmazonGroup = (partnerSlug: string) => new RegExp(allowedPartner.amazonGroup, 'i').test(partnerSlug);

export const isTicketingPartner = (partnerSlug?: string) => partnerSlug && TICKETING_PARTNERS.includes(partnerSlug);

export const isRevolut = (partnerSlug: string) => allowedPartner.revolut === partnerSlug;

export const showChatButton = (partnerSlug?: string) =>
  // On PASH page there is no partner id, so we check the url instead, e.g. /help/partners/amazon
  partnerSlug && !isAmazonGroup(partnerSlug) && (isTicketingPartner(partnerSlug) || isRevolut(partnerSlug));

export const showChatTab = (partnerSlug?: string) =>
  partnerSlug &&
  ((isAmazonGroup(partnerSlug) && AMAZON_COUNTRY_CODES.includes(getUserCountry())) || isTicketingPartner(partnerSlug));
