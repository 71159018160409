import { readableColor, transparentize, opacify, darken, rgba, tint } from 'polished';
import { Theme } from 'styled-components';
import {
  STATUS_CONFIRMED,
  STATUS_CANCELLED,
  STATUS_ACTIVE,
  STATUS_COMPLETED,
  STATUS_CLAIM_STARTED,
  STATUS_RECEIVED,
  STATUS_PENDING_PAYMENT,
} from '../constants';
import { IColorModifierHelperFunction, IThemeAsProp, IThemePropertyGetter, IThemeValueGetter } from '../types';

export const gray: IThemePropertyGetter<keyof Theme['grays']> =
  (colorName) =>
  ({ theme }) =>
    theme.grays[colorName];
export const color: IThemePropertyGetter<keyof Theme['colors']> =
  (colorName) =>
  ({ theme }) =>
    theme.colors[colorName];
export const themeColor: IThemePropertyGetter<keyof Theme['themeColors']> =
  (colorName) =>
  ({ theme }) =>
    theme.themeColors[colorName];

/**
 * Function to return a either dark or light color based on background color
 * to make it readable. Currently this function needs light and dark vars in
 * themeColors but that doesn't exist yet
 */
export const colorYIQ =
  (backgroundColor: any) =>
  ({
    theme: {
      themeColors: { light, dark },
    },
  }: any) =>
    readableColor(backgroundColor) === '#000' ? dark : light;

const getParsedColor = (colorValue: string | IThemeValueGetter, theme: Theme) =>
  (typeof colorValue === 'function' ? colorValue({ theme }) : colorValue) as string;

const getParsedAmount = (amount: number | IThemeValueGetter, theme: Theme) =>
  typeof amount === 'function' ? amount({ theme }) : amount;

export const transparentizeColor: IColorModifierHelperFunction =
  (amount, colorValue) =>
  ({ theme }) =>
    transparentize(getParsedAmount(amount, theme), getParsedColor(colorValue, theme));

export const opacifyColor: IColorModifierHelperFunction =
  (amount, colorValue) =>
  ({ theme }) =>
    opacify(getParsedAmount(amount, theme), getParsedColor(colorValue, theme));

export const darkenColor: IColorModifierHelperFunction =
  (amount, colorValue) =>
  ({ theme }) =>
    darken(getParsedAmount(amount, theme), getParsedColor(colorValue, theme));

export const tintColor: IColorModifierHelperFunction =
  (amount, colorValue) =>
  ({ theme }) =>
    tint(getParsedAmount(amount, theme), getParsedColor(colorValue, theme));

export const rgbaColor: IColorModifierHelperFunction =
  (amount, colorValue) =>
  ({ theme }) =>
    rgba(getParsedColor(colorValue, theme), getParsedAmount(amount, theme) as number);

export const statusColor: IThemePropertyGetter<string> =
  (status) =>
  ({ theme }) => {
    const { success, danger, warning, completed } = theme.themeColors;
    const colors = {
      [STATUS_ACTIVE]: success,
      [STATUS_CANCELLED]: danger,
      [STATUS_COMPLETED]: completed,
      [STATUS_CONFIRMED]: warning,
      [STATUS_CLAIM_STARTED]: warning,
      [STATUS_RECEIVED]: warning,
      [STATUS_PENDING_PAYMENT]: warning,
    };

    return colors[status as keyof typeof colors];
  };

export function inputColor<
  InputType extends keyof Theme['inputColors'],
  InputState extends keyof Theme['inputColors'][InputType],
>(inputType: InputType, inputState: InputState) {
  return ({ theme }: IThemeAsProp) => theme.inputColors[inputType][inputState];
}

export function toggleButtonColor(colorType: keyof Theme['toggleButtonColors']) {
  return ({ theme }: IThemeAsProp) => theme.toggleButtonColors[colorType];
}
