import { rem } from 'polished';
import styled from 'styled-components';
import { Input } from 'reactstrap';
import { toggleButtonColor } from '@whitelabel/helpers/style/colors';
import RadioWhite from '@whitelabel/media/icons/forms/radio-white.svg';
import RadioGray from '@whitelabel/media/icons/forms/radio-gray.svg';

export const StyledToggle = styled(Input)<{
  checked: boolean;
  disabled: boolean;
  $size: 'sm' | 'default';
}>`
  background-color: ${toggleButtonColor('default')};
  border-color: ${toggleButtonColor('default')};
  cursor: pointer;

  &.form-check-input {
    margin: 0;
    background-image: url(${RadioWhite});
    background-size: ${({ $size }) => ($size === 'sm' ? '0.75rem 0.75rem' : '1.25rem 1.25rem')};
    background-position: ${rem(4)} center;
    border-radius: ${rem(100)};
    width: ${({ $size }) => ($size === 'sm' ? rem(40) : rem(48))};
    height: ${({ $size }) => ($size === 'sm' ? rem(20) : rem(28))};
  }
  &.form-check-input:focus {
    background-image: url(${RadioWhite});
    border-color: transparent;
    box-shadow: none;
  }
  &.form-check-input:checked {
    background-color: ${toggleButtonColor('checked')};
    border-color: ${toggleButtonColor('checked')};
    background-image: url(${RadioWhite});
    background-position: right ${rem(4)} center;
  }
  &.form-check-input:hover:not(:focus) {
    background-color: ${toggleButtonColor('hover')};
    border-color: ${toggleButtonColor('hover')};
  }

  &.form-check-input:disabled {
    background-color: ${toggleButtonColor('disabled')};
    border-color: ${toggleButtonColor('disabled')};
    background-image: url(${RadioGray});
    background-position: ${({ checked }) => (checked ? `right ${rem(4)} center` : `${rem(4)} center`)};
  }
`;
